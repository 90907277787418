/* LIBS */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. */
a, area, button, input, label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Reset
   ========================================================================== */
h1, h2, h3, h4, h5, h6, p, blockquote, em, img, i, dl, dt, ol, ul, li, video, canvas {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

/** to remove the rounded in IOS*/
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

/* PARTIALS */
.icon-vintage-logo {
  width: 0.73em;
  height: 1em;
  fill: currentColor;
  stroke: initial;
}

/* COMPONENTS */
body {
  background-color: white;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;
}

/* PAGES */
.container {
  height: 100vh;
  width: 875px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 1100px) {
  .container {
    width: 319px;
  }
}

.logo {
  position: absolute;
  left: 0;
  bottom: 580px;
  z-index: 2;
}

@media (max-width: 1100px) {
  .logo {
    width: 145px;
    top: 59px;
    left: 81px;
  }
}

.img__front {
  position: absolute;
  left: -250px;
  bottom: -100px;
  z-index: 1;
}

@media (max-width: 1100px) {
  .img__front {
    width: 199px;
    height: 314px;
    left: 5px;
    top: -16px;
  }
}

.img__middle {
  position: absolute;
  bottom: -250px;
  left: -50px;
}

@media (max-width: 1100px) {
  .img__middle {
    width: 235px;
    height: 290px;
    left: 60px;
    top: 0;
  }
}

.img__small {
  position: absolute;
  bottom: -100px;
  left: 500px;
}

@media (max-width: 1100px) {
  .img__small {
    width: 92px;
    height: 114px;
    left: 170px;
    top: 141px;
  }
}

.content {
  position: absolute;
  max-width: 506px;
  left: 0;
  z-index: 2;
  bottom: 100px;
}

@media (max-width: 1100px) {
  .content {
    left: 46px;
    top: 208px;
  }
}

.content__title {
  max-width: 344px;
  color: #0b0b09;
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 24px;
}

@media (max-width: 1100px) {
  .content__title {
    font-size: 24px;
    max-width: 100px;
    top: 208px;
    margin-bottom: 47px;
  }
}

.content__text {
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  color: #0b0b09;
}

@media (max-width: 1100px) {
  .content__text {
    font-size: 16px;
    max-width: 253px;
    line-height: 24px;
  }
}

.contact {
  position: absolute;
  right: 0;
  bottom: 100px;
  z-index: 2;
  max-width: 270px;
}

@media (max-width: 1100px) {
  .contact {
    max-width: 131px;
    left: 46px;
    top: 424px;
  }
}

.contact__link {
  font-size: 32px;
  font-weight: 300;
  color: #0b0b09;
  line-height: 48px;
  display: inline-block;
  text-decoration: none;
  position: relative;
}

.contact__link:after {
  position: absolute;
  content: "";
  display: block;
  bottom: 6px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ca242a;
  transition: .3s;
}

.contact__link:hover:after {
  transform: scaleX(0);
}

@media (max-width: 1100px) {
  .contact__link {
    font-size: 16px;
    max-width: 131px;
    line-height: 24px;
  }
}

.gradient {
  height: 418px;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 1100px) {
  .gradient {
    top: 100px;
    height: 203px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 85%);
  }
}
