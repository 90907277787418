.container {
  height: 100vh;
  width: 875px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media (max-width: 1100px) {
    width: 319px;
  }
}

.logo {
  position: absolute;
  left: 0;
  bottom: 580px;
  z-index: 2;
  @media (max-width: 1100px) {
    width: 145px;
    top: 59px;
    left: 81px;
  }
}

.img {
  &__front {
    position: absolute;
    left: -250px;
    bottom: -100px;
    z-index: 1;
    @media (max-width: 1100px) {
      width: 199px;
      height: 314px;
      left: 5px;
      top: -16px;
    }
  }
  &__middle {
    position: absolute;
    bottom: -250px;
    left: -50px;
    @media (max-width: 1100px) {
      width: 235px;
      height: 290px;
      left: 60px;
      top: 0;
    }
  }
  &__small {
    position: absolute;
    bottom: -100px;
    left: 500px;
    @media (max-width: 1100px) {
      width: 92px;
      height: 114px;
      left: 170px;
      top: 141px;
    }
  }
}

.content {
  position: absolute;
  max-width: 506px;
  left: 0;
  z-index: 2;
  bottom: 100px;
  @media (max-width: 1100px) {
    left: 46px;
    top: 208px;
  }
  &__title {
    max-width: 344px;
    color: #0b0b09;
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 24px;
    @media (max-width: 1100px) {
      font-size: 24px;
      max-width: 100px;
      top: 208px;
      margin-bottom: 47px;
    }
  }
  &__text {
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
    color: #0b0b09;
    @media (max-width: 1100px) {
      font-size: 16px;
      max-width: 253px;
      line-height: 24px;
    }
  }
}

.contact {
  position: absolute;
  right: 0;
  bottom: 100px;
  z-index: 2;
  max-width: 270px;
  @media (max-width: 1100px) {
    max-width: 131px;
    left:46px;
    top: 424px;
  }
  &__link {
    font-size: 32px;
    font-weight: 300;
    color: #0b0b09;
    line-height: 48px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      display: block;
      bottom: 6px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #ca242a;
      transition: .3s;
    }
    &:hover:after{
      transform: scaleX(0);
    }
    @media (max-width: 1100px) {
      font-size: 16px;
      max-width: 131px;
      line-height: 24px;
    }
  }
}

.gradient {
  height: 418px;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  @media (max-width: 1100px) {
    top: 100px;
    height: 203px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 85%);
  }
}