/* LIBS */
@import 'libs/vintage-reset-normalize';

/* PARTIALS */
@import 'partials/_mixins';
@import 'partials/_variables';
@import 'partials/_fonts';
@import 'partials/_helpers';
@import 'partials/_keyframes';
@import 'partials/_svg';

/* COMPONENTS */
@import 'components/common';

/* PAGES */
@import 'pages/home';